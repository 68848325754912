<template>
    <modal name="profile-task-modal" ref="profile-task-modal" width="50%" height="55%" class="rounded-full" v-bind="$attrs" v-on="$listeners">
        <div class="flex flex-col justify-between min-h-full h-fullbg-card-bg h-full">
            <div class="modal-header w-full min-h-12 flex items-center justify-between px-6 bg-primary">
                <h3 class="font-semibold text-white-text flex items-center justify-center gap-2">
                    <TaskIcon />
                    Task
                </h3>
                <span class="__icon flex flex-col translate-x-full relative" role="button" @click="closeModal">
                    <CloseIcon />
                </span>
            </div>
            <div class="container px-6 flex-grow w-full max-w-full overflow-y-auto scroll-bar bg-card-bg">
                <!-- form ui -->
                <div class="call-form-wrapper w-full h-full">
                    <!-- <div class="flex items-center justify-end gap-2"></div> buttons to be added in this div-->
                    <FormulateForm class="call-form-container flex flex-col py-4 gap-4 w-full h-full">
                        <div class="flex flex-wrap gap-4 gap-y-3 items-end">
                            <Field class="w-48" type="date" :disabled-date="DisableDate" title="Due Date" v-model="modalData.dueDate" @input="handleDateChange">
                                <template #icon>
                                    <DateIcon />
                                </template>
                            </Field>
                            <Field class="w-40" type="time" :show-minute="false" :show-second="false" format="HH" :disabled-time="disabledHours" v-model="modalData.time">
                                <template #icon>
                                    <TimeIcon />
                                </template>
                            </Field>
                            <template>
                                <dv-dropdown
                                class=""
                                width="170px"
                                placeholder="Assignee"
                                :colors="{
                                    bg: '#E2E8F0',
                                    text: '#282828',
                                    border: '#E2E8F0',
                                    svg: '#0D69D5',
                                }"
                                :options="groupedAssigneeOptions"
                                :config="{ label: 'user_name', 'track-by': 'user_name', 'group-values': 'assignee', 'group-label': 'group_name', 'allow-empty': false }"
                                @input="(value) => onAssigneChange(value)"
                                v-model="assignee"
                                :allowEmpty="false"
                                />
                            </template>
                        </div>
                        <div class="note-text-area gap-5 h-full flex flex-col flex-1 items-start w-full">
                            <quill-editor v-model="modalData.note" ref="myQuillEditor" :options="editorOptions" />
                        </div>
                    </FormulateForm>
                </div>
            </div>
            <footer class="flex items-end justify-between pb-4 px-6 bg-card-bg">
                <!-- TODO -->
                <!-- <div
        class="footer-left-actions h-14 border-none py-4 px-9 cursor-pointer rounded text-sm flex items-center gap-4 bg-primary text-white-text">
        <label for="mail_attachments" class="cursor-pointer relative w-8 h-8 grid place-content-center">
          <EncloseIcon />
          <span v-if="attachedFiles.length"
            class="absolute -top-1 right-0 bg- text-black rounded-full w-3 h-3 grid place-content-center">
            {{ attachedFiles.length }}
          </span>
        </label>

        <input @change="fetchFileName('mail_attachments')" id="mail_attachments" type="file" name="mail_attachments"
          multiple="multiple" class="hidden" />
        <span>
          <DelIcon @click="resetAttachments" /></span>
      </div> -->
                <div  class="ml-auto relative">
                    <button type="submit" class="transition rounded-lg bg-blue-600 hover:bg-blue-700 focus:bg-gray-400 border-0 text-white-text-600 text-sm h-9 font-semibold px-4" @click="saveForm">Log Activity</button>
                </div>
            </footer>
        </div>
    </modal>
</template>

<script>
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.snow.css";
// import Button from "@/components/button/index.vue";
import Field from "@/components/sc-journey/field-modal.vue";
import CloseIcon from "@/assets/icons/email-admin/icon-crossbt.svg";
// import { checkPermission } from "@shared/utils/functions"
import TaskIcon from "@/assets/icons/profile-view/wTask.svg";
import DateIcon from "@/assets/icons/dashboard/date.svg";
import TimeIcon from "@/assets/icons/dashboard/clock.svg";
const DvDropdown = () => import("@/components/dropdown-base");
import { cloneDeep } from "lodash";
export default {
    name: "profile-task-modal",
    props: {
        assigneeOptions: {
            type: Array,
            default: () => [],
        },
    },
    components: {
        quillEditor,
        // "neo-button": Button,
        TaskIcon,
        Field,
        CloseIcon,
        DateIcon,
        TimeIcon,
        DvDropdown
    },
    data() {
        return {
            editorOptions: {
                debug: "info",
                placeholder: "type your text here",
                theme: "snow",
                modules: {
                    toolbar: [[{ size: ["small", false, "large", "huge"] }], ["bold", "italic", "underline", "strike"]],
                },
            },
            modalData: {
                dueDate: "",
                time: "",
                note: "",
                task_assignee_id: "",
                task_assignee_type: ""
            },
            assignee: null
        };
    },
    mounted() {
        this.closeModal();
    },
    methods: {
        // checkPermission,
        closeModal() {
            this.modalData = {
                dueDate: "",
                time: "",
                note: "",
                task_assignee_id: "",
                task_assignee_type: "",
            }
            this.assignee = null;
            this.$modal.hide("profile-task-modal");
        },
        handleDateChange() {
            if (this.modalData.dueDate) {
                let dueDate = new Date(new Date(this.modalData.dueDate).setHours(0, 0, 0, 0)).getTime();
                let today = new Date(new Date().setHours(0, 0, 0, 0)).getTime();
                if (dueDate === today) {
                    this.modalData = { ...this.modalData, time: new Date(new Date().setHours(new Date().getHours() + 1, 0, 0)) };
                }else{
                    this.modalData = { ...this.modalData, time: new Date(new Date().setHours(0, 0, 0)) };
                }
            }
        },
        saveForm() {
            this.modalData.note = this.modalData.note.replace(/<\/?[^>]+>/ig, "").trim();
            if(this.modalData.note === ""){
                this.$toast.error("Empty note are not allowed!");
                return
            }
            let modalData = this.modalData;
            this.modalData.note = this.modalData.note.trim();
            const qs = Object.keys(modalData)
                .filter((key) => modalData[key] === "")
                .map((key) => `${key}`);
            if (qs.length > 0) {
                this.$toast.error("Please fill all mandatory fields");
            } else {
                this.$emit("submit", this.modalData);
                this.closeModal();
                this.resetForm();
            }
        },
        resetForm() {
            this.modalData = {
                dueDate: "",
                time: "",
                note: "",
            };
        },
        DisableDate(date) {
            return date < new Date(new Date().setHours(0, 0, 0, 0));
        },
        disabledHours(date) {
            let dueDate = new Date(new Date(this.modalData.dueDate).setHours(0, 0, 0, 0)).getTime();
            let today = new Date(new Date().setHours(0, 0, 0, 0)).getTime();
            if (dueDate === today) {
                return date.getHours() <= new Date().getHours();
            }
        },
        onAssigneChange(value) {
            this.assignee = value
            this.modalData.task_assignee_id = value?.id ?? null;
            this.modalData.task_assignee_type = value?.user_type ?? null;
        }
    },
    computed: {
        groupedAssigneeOptions() {
            let user_map = {
                "screener": "analyst",
                "candidate": "subject",
            }
            let options = cloneDeep(this.assigneeOptions.map(el => ({
                ...el,
                user_group: el.user_type in user_map ? user_map[el.user_type] : el.user_type 
            })));
            let result = options.reduce(function (r, a) {
                r[a.user_group] = r[a.user_group] || [];
                r[a.user_group].push(a);
                return r;
            }, Object.create(null));
            result = Object.entries(result).map(([k, v]) => ({ group_name: k, assignee: v }));
            return result;
        },
    }
};
</script>

<style lang="scss" scoped>
.modal-header {
    &__icon {
        fill: white;
        * {
            fill: white;
        }
    }
}
</style>
